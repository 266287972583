@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body {
  height: 100vh;
  margin: 0; /* Optionally, remove default margin */
}

.font-formatted {
  font-family: "Tajawal", sans-serif;
  transition: ease 0.2s;
  margin: 0;
}
